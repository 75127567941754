import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import axios from "axios";
import swal from "sweetalert";
import "./styles.css";

const SuccessPayment = () => {
    const [showModal, setShowModal] = useState(true);
    const [hasError, setHasError] = useState(true);  // State to track if there's an error

    const navigate = useNavigate();
    const location = useLocation();

    const deleteOrder = async () => {
        try {
            const queryParams = new URLSearchParams(location.search);
            const orderId = queryParams.get("ID");

            // Send the ID to the backend
            const response = await axios.delete(`/order/${orderId}`);


            if (response.status === 200) {
                console.log("Order deleted successfully.");
            } else {
                console.error("Failed to delete order:", response.data);
            }
        } catch (error) {
            console.error("Error deleting order:", error);
        }
    };


    const handleExpiredStatus = () => {
        setShowModal(false);
        swal({
            title: "Order Expired",
            text: "Unfortunately, your order has expired. Please begin again by signing up to continue.",
            className: "text-center-swal",
            icon: "error",
            buttons: {
                confirm: "Go to Login",
            },
        }).then(() => {
            deleteOrder();
            navigate("/package");
        });
    };

    useEffect(() => {
        const completeAdminSubscription = async () => {
            try {
                const response = await axios.post(`/admin/subscription/complete`);

                if (response.status === 200) {
                    console.log("Order completed successfully");
                } else {
                    console.error("Failed to complete order:", response.data);
                }
            } catch (error) {
                console.error("Error completing order:", error);
            }
        };

        const completeOrderStatus = async () => {
            try {
                const queryParams = new URLSearchParams(location.search);
                const orderId = queryParams.get("ID");

                // Send the ID to the backend
                const response = await axios.get(`/complete/order/status`, { params: { orderId } });

                if (response.status === 200) {
                    setHasError(false);
                    console.log("Order status updated successfully.");
                } else if (response.status === 403) {
                    navigate("/package");
                } else {
                    console.error("Unexpected response:", response);
                }
            } catch (error) {
                console.error("Error checking subscription:", error);

                if (error.response.status === 403) {
                    setHasError(true);  // Set error state to true
                    handleExpiredStatus();
                }
            };
        };

        if (!hasError) {
            completeAdminSubscription();
            completeOrderStatus();
        }
        completeAdminSubscription();
        completeOrderStatus();

        const timer = setTimeout(() => {
            setShowModal(false);
            navigate("/dashboard");
        }, 5000);

        // Cleanup the timeout on component unmount
        return () => clearTimeout(timer);
    }, [navigate, hasError]);

    return (
        <div className="main-div">
            {/* Modal */}
            {!hasError && (
                <Modal show={showModal} centered>
                    <Modal.Header
                        className="text-white"
                    >
                        <Modal.Title className="w-100 text-center" style={{ fontSize: '2rem' }}>
                            Thank You for Subscribing!
                        </Modal.Title>
                    </Modal.Header>

                    <Modal.Body className="text-center" style={{ fontSize: '1.25rem', backgroundColor: 'rgba(255, 255, 255, 0.9)', borderRadius: '0 0 10px 10px' }}>
                        <p>We're so glad to have you on board. You will be redirected to your dashboard shortly...</p>
                    </Modal.Body>

                    <Modal.Footer className="d-flex justify-content-center" style={{ backgroundColor: 'transparent' }}>
                        <Button
                            variant="success"
                            size="lg"
                            onClick={() => {
                                setShowModal(false);
                                navigate("/dashboard");
                            }}
                            style={{
                                backgroundColor: '#452b90',
                                fontSize: '1.2rem',
                                padding: '10px 20px',
                                borderRadius: '8px',
                                transition: 'all 0.3s ease',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
                            }}
                            className="shadow-sm"
                        >
                            Go to Dashboard
                        </Button>
                    </Modal.Footer>
                </Modal>
            )}

            {/* Content */}
            <section className="product-container">
                <div className="product-card-container">
                    <div className="product-card">
                    </div>
                </div>
            </section>
        </div>
    );
};

export default SuccessPayment;
