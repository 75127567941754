import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import "./styles.css";
import swal from "sweetalert";
import { Logout } from "../../store/actions/AuthActions";
import { Button, Modal } from "react-bootstrap";
import LogoutPage from "../layouts/nav/Logout";
import useAdmins from "../../hooks/useAdmins";
import usePayments from "../../hooks/usePayments";
import MasterCardLogo from "../../images/logos/MasterCardLogo.png";
import VisaLogo from "../../images/logos/VisaLogo.png";
import MarathonRace from "../../images/marathon_Race.jpg";

const Packages = () => {
    const [loading, setLoading] = useState(true);
    const [admin, setAdmin] = useState(null); // Initialize user state
    const [adminVerified, setAdminVerified] = useState(false);
    const [email, setEmail] = useState("");
    const [participants, setParticipants] = useState("");
    const [totalPrice, setTotalPrice] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [renderSection, setRenderSection] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { fetchAdminHandler, verifyEmailHandler } = useAdmins();
    const { fetchAllPayments } = usePayments();

    const procredit_api = process.env.REACT_APP_API_URL_PROCREDIT_TEST;
    const server_url = process.env.REACT_APP_SERVER_URL;

    function onLogout() {
        dispatch(Logout(navigate));
    }

    const redirectOrder = async (returnedOrder) => {
        const order = returnedOrder.data.order;

        window.location.href = `${procredit_api}/flex?id=${order.orderId}&password=${order.password}`;
    };

    useEffect(() => {
        const checkAndRedirect = async () => {
            try {
                const orderResponse = await axios.get(`/order`);

                // If an order exists, redirect to payment
                if (orderResponse?.data?.order) {
                    redirectOrder(orderResponse);
                } else {
                    console.log("No order found for admin");
                }
            } catch (error) {
                console.error("Error checking for order or fetching admin data:", error.message);
            } finally {
                setLoading(false);
            }
        };

        checkAndRedirect();


        const timer = setTimeout(() => {
            setRenderSection(true);
        }, 1200);

        return () => clearTimeout(timer);
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const [productsResponse, adminResponse] = await Promise.all([
                    fetchAllPayments(),
                    fetchAdminHandler(),
                ]);
                if (adminResponse.admin.subscription.isActive === true) {
                    navigate("/dashboard");
                }
                if (adminResponse.admin.isVerified === true) {
                    setAdminVerified(true);
                }
                setEmail(adminResponse.admin.email);
                setAdmin(adminResponse.admin);
            } catch (error) {
                console.error("Error fetching data:", error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const createOrder = async () => {
        if (isNaN(totalPrice) || totalPrice <= 0) {
            swal({
                title: "Invalid price",
                text: "Please enter a valid number of participants.",
                icon: "warning",
                buttons: {
                    cancel: "OK",
                },
            });
            return;
        }
        const orderData = {
            amount: totalPrice.toFixed(2),
        };

        const redirectUrl = `${server_url}/create-order?amount=${orderData.amount}`;

        window.location.href = redirectUrl;
    };

    const handleParticipantsChange = (event) => {
        const enteredNumber = event.target.value;

        if (/[^0-9]/.test(enteredNumber)) {
            return;
        }

        const numParticipants = parseInt(event.target.value, 10) || "";
        setParticipants(numParticipants);

        // Calculate price based on the number of participants
        let pricePerParticipant = 2; // Default price is 2 euros
        if (numParticipants >= 101 && numParticipants <= 250) {
            pricePerParticipant = 1.5; // Price for 101-250 participants
        } else if (numParticipants > 250) {
            pricePerParticipant = 1; // Price for more than 250 participants
        }

        const total = numParticipants * pricePerParticipant;
        setTotalPrice(total);
    };

    const handleVerify = async () => {
        try {
            const response = await verifyEmailHandler(email);
            if (response.status === 200) {
                swal({
                    title: "Email sent successfully!",
                    text: response.message,
                    icon: "success",
                    buttons: {
                        cancel: "OK",
                    },
                });
            } else {
                swal({
                    title: "Error",
                    text: response.error,
                    icon: "error",
                    buttons: {
                        cancel: "OK",
                    },
                });
            }
        } catch (error) {
            console.error("Error verifying email:", error.message);
        }
    };

    const handleNotVerified = () => {
        swal({
            title: "Please verify your email first!",
            text: "Check your email for the verification link.",
            icon: "warning",
            buttons: {
                cancel: "OK",
            },
        });
    };

    if (loading) {
        return (
            <div className="loading-div">
                <div className="spinner-border" role="status">
                    <span className="visually-hidden"></span>
                </div>
            </div>
        );
    }

    const handleShowModal = () => setShowModal(true);
    const handleCloseModal = () => setShowModal(false);

    const checkNumberSign = (num) => {
        if (num > 0) {
            return true;
        } else {
            return false;
        }
    }

    return (
        <div className="main-div">

            {!adminVerified && (
                <div className="warning-div">
                    <p id="warning">
                        Verification has been sent to your email!{" "}
                        <u>
                            <b>
                                <button className="link-button" onClick={handleVerify}>
                                    Resend Verification
                                </button>
                            </b>
                        </u>
                    </p>
                </div>
            )}

            {admin !== null && (
                <>
                    <div className="user-info" style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
                        <Button
                            style={{ marginRight: "auto", marginLeft: "40px", backgroundColor: "#f8b940" }}
                            onClick={handleShowModal}
                        >
                            Info
                        </Button>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <p style={{ marginTop: "15px" }} >{admin.username}</p>
                            <Button variant="primary btn-xs" onClick={onLogout} style={{ marginLeft: "10px" }}>
                                Logout
                            </Button>
                        </div>
                    </div>
                </>
            )}

            <section className="product-container">
                <div className="product-card-container">
                    <div className="product-card">
                        <div className="product-description text-center">
                            <h2>Standard Package</h2>

                            {renderSection ? (
                                <div className="image-wrapper">
                                    <img
                                        src={MarathonRace}
                                        alt="Running snapshot"
                                    />
                                </div>
                            ) : (
                                <div className="image-wrapper">
                                    <div className="spinner-border" role="status">
                                        <span className="visually-hidden"></span>
                                    </div>
                                </div>
                            )}

                            {
                                !totalPrice ? (
                                    <h5 className="text-muted">Write the number of participants</h5>
                                ) : (
                                    <h5>Total: <span className={checkNumberSign(totalPrice) ? "text-success" : "text-danger"}>${totalPrice.toFixed(2)}</span></h5>
                                )
                            }

                        </div>

                        <div className="participants-input">
                            <input
                                id="participants"
                                type="text"
                                value={participants}
                                placeholder="Number of participants"
                                onChange={handleParticipantsChange}
                                className="form-control mb-3 text-center"
                                inputMode="numeric"
                            />
                        </div>


                        <div className="product-form">
                            <input type="hidden" name="price" value={totalPrice * 100} />
                            <input type="hidden" name="participants" value={participants} />

                            {adminVerified ? (
                                <button onClick={createOrder} className="product-button">
                                    Proceed to payment
                                </button>
                            ) : (
                                <button
                                    type="button"
                                    className="product-button"
                                    onClick={handleNotVerified}
                                >
                                    Proceed to payment
                                </button>
                            )}
                        </div>

                        <div className="row mt-4">
                            <div className="col-6 d-flex justify-content-center">
                                <img
                                    src={MasterCardLogo}
                                    alt="MasterCard Logo"
                                    className="img-fluid" // Makes the logo responsive
                                    style={{ maxWidth: '70%', height: 'auto' }} // Adjust size if needed
                                />
                            </div>
                            <div className="col-6 d-flex justify-content-center">
                                <img
                                    src={VisaLogo}
                                    alt="Visa Logo"
                                    className="img-fluid" // Makes the logo responsive
                                    style={{ maxWidth: '70%', height: 'auto' }} // Adjust size if needed
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section >

            <Modal show={showModal} onHide={handleCloseModal} centered>
                <Modal.Header
                    closeButton
                    style={{
                        color: 'white',
                        borderTopLeftRadius: '15px',
                        borderTopRightRadius: '15px',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',
                    }}
                >
                    <Modal.Title className="w-100 text-center" style={{ fontSize: '1.5rem', fontWeight: 'bold' }}>
                        Pricing Strategy Explanation
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body
                    style={{
                        backgroundColor: '#f9f9f9',
                        borderBottomLeftRadius: '15px',
                        borderBottomRightRadius: '15px',
                        padding: '2rem',
                        fontSize: '1rem',
                        color: '#333',
                        lineHeight: '1.6',
                        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    <p>The price per participant is calculated as follows:</p>
                    <ul style={{ paddingLeft: '1.5rem' }}>
                        <li style={{ fontSize: '1rem', marginBottom: '1rem' }}>
                            <strong>1 - 100 participants:</strong> €2.00 per participant
                        </li>
                        <li style={{ fontSize: '1rem', marginBottom: '1rem' }}>
                            <strong>101 - 250 participants:</strong> €1.50 per participant
                        </li>
                        <li style={{ fontSize: '1rem', marginBottom: '1rem' }}>
                            <strong>250+ participants:</strong> €1.00 per participant
                        </li>
                    </ul>
                    <p style={{ fontWeight: 'bold' }}>
                        For example, if you have 150 participants, the price will be 150 x €1.50 = €225.00.
                    </p>
                </Modal.Body>

                <Modal.Footer
                    style={{
                        backgroundColor: 'transparent',
                        borderTop: 'none',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: '1rem',
                    }}
                >
                    <Button
                        variant="secondary"
                        onClick={handleCloseModal}
                        style={{
                            fontSize: '1rem',
                            padding: '10px 20px',
                            borderRadius: '30px',
                            fontWeight: 'bold',
                            transition: 'all 0.3s ease',
                            boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.1)',
                        }}
                        className="shadow-sm"
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </div >
    );
};

export default Packages;
