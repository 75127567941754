import axios from "axios";

const getAdmin = async () => {
    try {
        const response = await axios.get('/currentAdmin');
        return response.data;
    }
    catch (error) {
        console.error("Failed to fetch admin", error);
    }
}

const registerAdmin = async (adminData) => {
    try {
        const response = await axios.post('/api/auth/register', adminData);
        console.log("test");

        return response.data;
    }
    catch (error) {
        console.error("Failed to register admin", error);
    }
}

const loginAdmin = async (adminData) => {
    try {
        const response = await axios.post('/api/auth/login', adminData);
        return response.data;
    }
    catch (error) {
        console.error("Failed to login admin", error);
    }
}

const logoutAdmin = async () => {
    try {
        const response = await axios.get('/logout');
        return response.data;
    }
    catch (error) {
        console.error("Failed to logout admin", error);
    }
}

const changePassword = async (adminId, adminData) => {
    try {
        const response = await axios.post(`/api/auth/${adminId}/change-password`, adminData);
        return response.data;
    }
    catch (error) {
        console.error("Failed to update admin", error);
    }
}

const editAdmin = async (adminId, adminData) => {
    try {
        const response = await axios.post(`/api/auth/${adminId}/edit-profile`, adminData);
        return response.data;
    }
    catch (error) {
        console.error("Failed to update admin", error);
    }
}

const generateToken = async (adminId) => {
    try {
        const response = await axios.post(`/api/generate-token/${adminId}/generate-token`);
        return response.data;
    }
    catch (error) {
        console.error("Failed to generate token", error);
    }
}

const resetPassword = async (token) => {
    try {
        const response = await axios.post("/reset-password", {
            token,
        });
        return response;
    }
    catch (error) {
        console.error("Failed to reset password", error);
    }
}

const resetPasswordChange = async (token) => {
    try {
        const response = await axios.post('/reset-password-change', token);
        return response;
    }
    catch (error) {
        console.error("Failed to reset password", error);
    }
}

const verifyEmail = async (email) => {
    try {
        const response = await axios.post("/api/verify-email", {
            secret: process.env.REACT_APP_VERIFY_EMAIL_SECRET,
            email: email,
        });
        return response;
    }
    catch (error) {
        console.error("Failed to verify email", error);
    }
}

const verifyEmailChange = async (adminData) => {
    try {
        const response = await axios.post('/api/auth/verify-email', adminData);
        return response;
    }
    catch (error) {
        console.error("Failed to verify email", error);
    }
}

const uploadImage = async (formData) => {
    try {
        const response = await axios.post('/upload-image', formData);
        return response.data;
    }
    catch (error) {
        console.error("Failed to upload image", error);
    }
}

const deleteAccount = async (password) => {
    try {
        const response = await axios.delete(`/delete-account`, {
            data: { password }, // Send the password as the request body
        });
        return response.data;
    }
    catch (error) {
        console.error("Failed to delete account", error);
    }
}

const cancelSubscription = async (adminData) => {
    try {
        const response = await axios.post("/api/send-cancellation-request", adminData);
        return response;
    }
    catch (error) {
        console.error("Failed to cancel subscription", error);
    }
}

export {
    getAdmin,
    registerAdmin,
    loginAdmin,
    logoutAdmin,
    changePassword,
    editAdmin,
    generateToken,
    resetPassword,
    resetPasswordChange,
    verifyEmail,
    verifyEmailChange,
    uploadImage,
    deleteAccount,
    cancelSubscription
}
