import { getAdmin, registerAdmin, uploadImage, changePassword, resetPasswordChange, verifyEmailChange, editAdmin, resetPassword, verifyEmail, deleteAccount, cancelSubscription } from "./requests/adminRequests";
const useAdmins = () => {
    const fetchAdminHandler = async () => {
        try {
            const response = await getAdmin();
            return response;
        }
        catch (error) {
            console.error("Failed to fetch admin", error);
        }
    }
    const registerAdminHandler = async (adminData) => {
        try {
            const response = await registerAdmin(adminData);
            return response;
        }
        catch (error) {
            console.error("Failed to register admin", error);
        }
    }
    const changePasswordHandler = async (adminId, adminData) => {
        try {
            const response = await changePassword(adminId, adminData);
            return response;
        }
        catch (error) {
            console.error("Failed to change password", error);
        }
    }
    const editAdminHandler = async (adminId, adminData) => {
        try {
            const response = await editAdmin(adminId, adminData);
            return response;
        }
        catch (error) {
            console.error("Failed to update admin", error);
        }
    }
    const uploadImageHandler = async (formData) => {
        try {
            const response = await uploadImage(formData);
            return response;
        }
        catch (error) {
            console.error("Failed to upload image", error);
        }
    }
    const deleteAccountHandler = async (password) => {
        try {
            const response = await deleteAccount(password);
            return response;
        }
        catch (error) {
            console.error("Failed to delete account", error);
        }
    }
    const verifyEmailHandler = async (email) => {
        try {
            const response = await verifyEmail(email);
            return response;
        } catch (error) {
            console.error("Failed to verify email", error);
        }
    }
    const verifyEmailChangeHandler = async (adminData) => {
        try {
            const response = await verifyEmailChange(adminData);
            return response;
        }
        catch (error) {
            console.error("Failed to verify email", error);
        }
    }
    const resetPasswordHandler = async (token) => {
        try {
            const response = await resetPassword(token);
            return response;
        }
        catch (error) {
            console.error("Failed to reset password", error);
        }
    }
    const resetPasswordChangeHandler = async (token) => {
        try {
            const response = await resetPasswordChange(token);
            return response;
        }
        catch (error) {
            console.error("Failed to reset password", error);
        }
    }
    const cancelSubscriptionHandler = async (adminData) => {
        try {
            const response = await cancelSubscription(adminData);
            return response;
        }
        catch (error) {
            console.error("Failed to cancel subscription", error);
        }
    }

    return { fetchAdminHandler, registerAdminHandler, changePasswordHandler, verifyEmailChangeHandler, resetPasswordChangeHandler, verifyEmailHandler, editAdminHandler, deleteAccountHandler, uploadImageHandler, resetPasswordHandler, cancelSubscriptionHandler };
}

export default useAdmins;